<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row">
      <div class="col-md-8 text-start d-flex">
        <button
          style="margin-left: 19px"
          type="button"
          class="badge bg-secondary rounded blueFit fs-5"
          data-bs-toggle="modal"
          data-bs-target="#modal_add_pagamento_sanzioni"
          v-if="
            sanzioniSelezionate.length &&
            !readOnly &&
            puo_utilizzare_pulsanti_sezioni
          "
          @click="showPopupSelect()"
        >
          Paga selezionate
        </button>
        <button
          v-if="
            !readOnly &&
            !sanzioniSelezionate.length &&
            puo_utilizzare_pulsanti_sezioni
          "
          style="margin-left: 19px"
          type="button"
          class="badge bg-secondary rounded blueFit fs-5"
          @click="showPopupSelect()"
        >
          Paga selezionate
        </button>
        <!--   <span type="button" class="badge bg-light-danger rounded text-danger">
          Elimina selezionati
        </span> -->
      </div>
      <div class="col-md-4 text-end mt-4">
        <div style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        societa_sanzioni_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="societa_sanzioni_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        :checkedAll="checkAll"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsSanzioniUnaSoc"
        @current-change="setCurrentPageSanzioniUnaSoc"
        @checkboxClicked="
          checkAll = !checkAll;
          societa_sanzioni_list.forEach((element) => {
            if (!element.pagato || element.pagato == null) {
              checkAll ? (element.selected = true) : (element.selected = false);
            }
          });
          setSanzioniSelezionate();
        "
      >
        <template v-slot:cell-checkbox="{ row: data }"
          ><input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id"
            v-if="
              (!data.pagato || data.pagato == null) &&
              !readOnly &&
              puo_utilizzare_pulsanti_sezioni
            "
            @click="
              checkAll = false;
              data.selected = !data.selected;
              setSanzioniSelezionate();
            "
        /></template>
        <template v-slot:cell-organo_emittente="{ row: data }">{{
          data.organo_emittente
        }}</template>
        <template v-slot:cell-numero="{ row: data }">{{
          data.numero
        }}</template>
        <template v-slot:cell-causale="{ row: data }">{{
          data.causale
        }}</template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long-xl">{{ data.descrizione }}</div></template
        >
        <template v-slot:cell-importo="{ row: data }">
          <div :class="data.importo >= 0 ? 'text-success' : 'text-danger'">
            <div class="text-end">
              {{ data.importo.toFixed(2) }}
            </div>
          </div>
        </template>
        <template v-slot:cell-data_reg="{ row: data }"
          ><div class="text-center">{{ data.data_reg }}</div></template
        >
      </Datatable>
    </div>
    <AddPagamentoSanzioni
      @refreshList="refreshList"
      @setSpinnerView="setSpinnerView"
      ref="modalAddPagamentoSanzioni"
      :societa="societa"
      :sanzioniSelezionate="sanzioniSelezionate"
      @spliceSelezionate="sanzioniSelezionate = []"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import AddPagamentoSanzioni from "./AddPagamentoSanzioni.vue";
import isEnabled from "@/composables/isEnabled.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "table-sanzioni-una-societa",
  components: { Datatable, AddPagamentoSanzioni, Loading },
  emits: ["getSanzioniUnaSocietaList", "resetFilters"],
  props: {
    societa: {
      type: Object,
    },

    totaleDisponibile: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const societa_sanzioni_list = computed(() =>
      store.getters.getStateFromName("resultssocieta_sanzioni_list")
    );

    const checkAll = ref(false);

    const setFetchRowsSanzioniUnaSoc = (e) => {
      checkAll.value = false;
      store.commit("setFetchRowsSanzioniUnaSoc", e);
      emit("getSanzioniUnaSocietaList");
    };
    const setCurrentPageSanzioniUnaSoc = (page) => {
      checkAll.value = false;
      store.commit("setCurrentPageSanzioniUnaSoc", page);
      emit("getSanzioniUnaSocietaList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      checkAll.value = false;
      store.commit("setSortColumnSanzioniUnaSoc", columnName);
      store.commit("setSortOrderSanzioniUnaSoc", order);
      emit("getSanzioniUnaSocietaList");
    };
    const refreshList = () => {
      checkAll.value = false;
      emit("getSanzioniUnaSocietaList");
    };

    const sanzioniSelezionate = ref([]);
    const showModal = ref(false);

    const setSanzioniSelezionate = () => {
      showModal.value = false;
      sanzioniSelezionate.value = [];
      societa_sanzioni_list.value.forEach((element) => {
        if (element.selected) {
          sanzioniSelezionate.value.push(element.id);
        }
      });
      if (sanzioniSelezionate.value.length == 0) {
        showModal.value = false;
      } else {
        showModal.value = true;
      }
    };

    const showPopupSelect = () => {
      if (sanzioniSelezionate.value.length == 0) {
        Swal.fire({
          html: "Per procedere è necessario selezionare uno o più movimenti",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    const isLoading = ref(false);
    const fullPage = ref(true);

    const setSpinnerView = (value) => {
      isLoading.value = value;
    };

    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Org. emitt.",
        key: "organo_emittente",
        sortable: false,
      },
      {
        name: "Numero",
        key: "numero",
      },
      {
        name: "Causale",
        key: "causale",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Importo",
        key: "importo",
        align: "end",
      },
      {
        name: "Data reg.",
        key: "data_reg",
        align: "center",
      },
    ]);

    return {
      societa_sanzioni_list,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsocieta_sanzioni_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordsocieta_sanzioni_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statussocieta_sanzioni_list")
      ),
      currentPage: computed(() => store.getters.currentPageSanzioniUnaSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipSanzioniUnaSoc),
      fetchRows: computed(() => store.getters.fetchRowsSanzioniUnaSoc),
      sortColumn: computed(() => store.getters.sortColumnSanzioniUnaSoc),
      sortOrder: computed(() => store.getters.sortOrderSanzioniUnaSoc),
      setFetchRowsSanzioniUnaSoc,
      setCurrentPageSanzioniUnaSoc,
      setSortOrderColumn,
      tableHeader,
      checkAll,
      sanzioniSelezionate,
      setSanzioniSelezionate,
      isLoading,
      fullPage,
      setSpinnerView,
      refreshList,
      showModal,
      showPopupSelect,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
};
</script>
